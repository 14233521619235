@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.slide-in-right {
  animation: slide-in-right 0.5s ease-out forwards;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: fixed; */
  top: 10px;
  right: 1px;
  width: auto;
  max-width: 90%;
}

.slide-out-right {
  animation: slide-out-right 0.5s ease-out forwards;
}

.btn-close {
  position: absolute;
  top: 1px;
  right: 2px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: inherit;
}

.close-icon {
  font-size: 1rem;
  line-height: 1;
}

#alert-root{
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
}