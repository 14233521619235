@tailwind base;
@tailwind components;
@tailwind utilities;


/* Importa as fontes Arimo e Inter do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Define a fonte 'New Order' manualmente para diferentes pesos */
@font-face {
  font-family: 'New Order';
  src: url('./assets/fonts/newOrder/fonnts.com-New_Order_Light.otf') format('opentype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('./assets/fonts/newOrder/fonnts.com-New_Order_Regular.otf') format('opentype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('./assets/fonts/newOrder/fonnts.com-New_Order_Medium.otf') format('opentype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('./assets/fonts/newOrder/fonnts.com-New_Order_Semibold.otf') format('opentype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'New Order';
  src: url('./assets/fonts/newOrder/fonnts.com-New_Order_Bold.otf') format('opentype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

/* Aplicar a fonte 'New Order' como padrão */
body {
  margin: 0;
  font-family: 'New Order', 'Inter', 'Arimo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
