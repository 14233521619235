  @media (max-width: 1250px), (min-height: 900px) {
    #home {
      background: none;
    }
  
    #home img {
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left top;
    }
  
    .hidden {
      display: none;
    }
  }

  @media (min-width: 1700px ) {
    #home {
      background: none;
    }
    #home img {
      width: 100%;
      height: 100%;
      max-width: none;
      object-fit: cover;
      object-position: left top;
    }
  
    .hidden {
      display: none;
    }
  }

    
  @media (max-width: 1600px) {
    #blue-blur {
      width: 57% !important;
    }
  }

  @media (max-width: 1440px) or (min-height: 768px) {
    #blue-blur {
      width: 50% !important;
    }
  }

  @media (max-width: 1440px) {
    #blue-blur-1 {
      width: 62% !important;
    }
  }

  @media (max-heigth: 1440px) and (min-width: 1300px) {
    #blue-blur {
      width: 50% !important;
    }
  }


  @media (max-width: 595px) { 
    #home h1 {
      font-size: 3rem;
    }
  
    #home p { 
      font-size: 1rem;
      line-height: 1.2
    }
  
    #home .LButton {
      font-size: 0.7rem;
    }

  }

  @media (max-width: 465px) {
    #home h1 {
      font-size: 2.5rem;
    }

    #home p { 
      font-size: .9rem;
      line-height: 1.2
    }

    .divButton {
        display: flex;
        flex-direction: column;
    }

    #home .LButton {
      font-size: 0.9rem;
    }
  }
  

  .btn-contrast-light {
    background-color: #ffffff; /* ou uma cor clara */
    color: #0056b3; /* cor do texto que contrasta bem */
    border: 2px solid #ffffff;
  }
  
  .btn-contrast-dark {
    background-color: #0056b3; /* cor escura para contraste */
    color: #ffffff; /* cor do texto que contrasta bem */
    border: 2px solid #0056b3;
  }
  
  .btn-hover {
    transition: background-color 0.3s ease;
  }
  
  .btn-contrast-light:hover,
  .btn-contrast-dark:hover {
    background-color: #003f7f; /* cor de destaque no hover */
  }
  