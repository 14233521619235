@media (min-width: 992px) {
  .col-20 {
    width: 20% !important;
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(50%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(50%) contrast(100%);
}

.carousel-indicators [data-bs-target] {
  background-color: #555 !important;
}

.carousel-indicators .active {
  background-color: #000 !important;
}


