@media (max-width: 1109px) {
    .menu-desktop {
      display: none;
    }
    .menu-mobile {
      display: block;
    }
  }
  
  @media (min-width: 1110px) {
    .menu-desktop {
      display: flex;
    }
    .menu-mobile {
      display: none;
    }
  }
  